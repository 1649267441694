<template>
	<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 left_side_bar e_chart_sidebar px-0">
		<div class="sticky-scroll-box">
			<ul class="side-bar-menu" data-widget="tree">
				<li :class="{ 'active': this.$route.name === 'PatientEChartAllergies' }" v-if='this.isAllergyRoute()'>
					<router-link :to="{ name: 'PatientEChartAllergies',params: { patient_id: $route.params.patient_id,  }}"> Overview</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEChartAllergies'">
				</li>
				<li :class="{ 'active': this.$route.name === 'PatientEChartConsultList' }" v-if='this.isConsultRoute()'>
					<router-link :to="{ name: 'PatientEChartConsultList',params: { patient_id: $route.params.patient_id,  }}"> Consults</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEChartConsultList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'PatientEChartSpecialistsList' }" v-if='this.isConsultRoute()'>
					<router-link :to="{ name: 'PatientEChartSpecialistsList',params: { patient_id: $route.params.patient_id,  }}"> Specialists</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEChartSpecialistsList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'PatientEChartPrescriptionList' }" v-if='this.isMedicationRoute()'>
					<router-link :to="{ name: 'PatientEChartPrescriptionList',params: { patient_id: $route.params.patient_id,  }}"> Prescriptions </router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEChartPrescriptionList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'PatientEChartDrugList' }" v-if='this.isMedicationRoute()'>
					<router-link :to="{ name: 'PatientEChartDrugList',params: { patient_id: $route.params.patient_id,  }}"> Drug List </router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEChartDrugList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'PatientEChartPreferredPharmacy' }" v-if='this.isMedicationRoute()'>
					<router-link :to="{ name: 'PatientEChartPreferredPharmacy',params: { patient_id: $route.params.patient_id,  }}"> Preferred Pharmacy </router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEChartPreferredPharmacy'">
				</li>
				<li :class="{ 'active': this.$route.name === 'PatientEChartImmunizationList' }" v-if='this.isPreventionsRoute()'>
					<router-link :to="{ name: 'PatientEChartImmunizationList',params: { patient_id: $route.params.patient_id,  }}"> Immunization</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEChartImmunizationList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'PatientEChartScreeningList' }" v-if='this.isPreventionsRoute()'>
					<router-link :to="{ name: 'PatientEChartScreeningList',params: { patient_id: $route.params.patient_id,  }}"> Screening</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEChartScreeningList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'PatientEChartActiveTaskList' }" v-if='this.isTasksRoute()'>
					<router-link :to="{ name: 'PatientEChartActiveTaskList',params: { patient_id: $route.params.patient_id,  }}"> Active </router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEChartActiveTaskList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'PatientEChartCompleteDeleteTaskList' }" v-if='this.isTasksRoute()'>
					<router-link :to="{ name: 'PatientEChartCompleteDeleteTaskList',params: { patient_id: $route.params.patient_id,  }}"> Complete / Delete </router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEChartCompleteDeleteTaskList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'AllPatientActiveTaskList' }" v-if='this.isAllTasksRoute()'>
					<router-link :to="{ name: 'AllPatientActiveTaskList'}"> Active </router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'AllPatientActiveTaskList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'AllPatientCompleteDeleteTaskList' }" v-if='this.isAllTasksRoute()'>
					<router-link :to="{ name: 'AllPatientCompleteDeleteTaskList'}"> Completed / Deleted </router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'AllPatientCompleteDeleteTaskList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'PatientEChartFilesOverview' }" v-if='this.isFileRoute()'>
					<router-link :to="{ name: 'PatientEChartFilesOverview',params: { patient_id: $route.params.patient_id,  }}"> Overview</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEChartFilesOverview'">
				</li>
				<!-- <li :class="{ 'active': this.$route.name === 'PatientEChartFilesDelete' }" v-if='this.isFileRoute()'>
					<router-link :to="{ name: 'PatientEChartFilesDelete',params: { patient_id: $route.params.patient_id,  }}"> Deleted Files</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEChartFilesDelete'">
				</li> -->
				<li :class="{ 'active': this.$route.name === 'EChartFilesOverview' }" v-if='this.isAllFileRoute()'>
					<router-link :to="{ name: 'EChartFilesOverview',params: {   }}"> Overview</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'EChartFilesOverview'">
				</li>
				<!-- <li :class="{ 'active': this.$route.name === 'EChartFilesDelete' }" v-if='this.isAllFileRoute()'>
					<router-link :to="{ name: 'EChartFilesDelete',params: {  }}"> Deleted Files</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'EChartFilesDelete'">
				</li> -->
				<li :class="{ 'active': this.$route.name === 'patientImagingList' }" v-if='this.isImagingRoute()'>
					<router-link :to="{ name: 'patientImagingList',params: { patient_id: $route.params.patient_id,  }}"> Overview</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'patientImagingList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'patientLabsList' }" v-if='this.islabsRoute()'>
					<router-link :to="{ name: 'patientLabsList',params: { patient_id: $route.params.patient_id,  }}"> Overview</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'patientLabsList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'patientTemplateList' }" v-if='this.isTemplateRoute()'>
					<router-link :to="{ name: 'patientTemplateList',params: { patient_id: $route.params.patient_id,  }}"> Overview</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'patientTemplateList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'patientMeasurementList' }" v-if='this.isMeasurementRoute()'>
					<router-link :to="{ name: 'patientMeasurementList',params: { patient_id: $route.params.patient_id,  }}"> Metric Records</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'patientMeasurementList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'patientMeasurementGroupList' }" v-if='this.isMeasurementRoute()'>
					<router-link :to="{ name: 'patientMeasurementGroupList',params: { patient_id: $route.params.patient_id,  }}"> Metric Groups</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'patientMeasurementGroupList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'templateMasterList' }" v-if='this.isTemplateMasterRoute()'>
					<router-link :to="{ name: 'templateMasterList',params: { }}"> Overview</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'templateMasterList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'AllLabList' }" v-if='this.isAllLabListRoute()'>
					<router-link :to="{ name: 'AllLabList',params: { }}"> Overview</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'AllLabList'">
				</li>
				<li :class="{ 'active': this.$route.name === 'AllImagingList' }" v-if='this.isAllImagingListRoute()'>
					<router-link :to="{ name: 'AllImagingList',params: { }}"> Overview</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'AllImagingList'">
				</li>
				
			</ul>
		</div>
	</div>
</template>
<script>


export default {
	data() {
		return {
			options:[
			]
		};
	},
	watch: {
		
	},
	mounted() {

    },
	methods: {
		isAllLabListRoute () {
			return this.$route.name === "AllLabList"
		},
		isAllImagingListRoute () {
			return this.$route.name === "AllImagingList"
		},
		isAllergyRoute () {
			return this.$route.name === "PatientEChartAllergies"
		},
		isConsultRoute() {
			return (
				this.$route.name === "PatientEChartConsultList" ||
				this.$route.name === "PatientEChartConsultAdd" ||
				this.$route.name ===  "PatientEChartSpecialistsList"
			)
		},
		isMedicationRoute() {
			return (
				this.$route.name === "PatientEChartPrescriptionList" ||
				this.$route.name === "PatientEChartDrugList" ||
				this.$route.name ===  "PatientEChartPreferredPharmacy"
			)
		},
		isPreventionsRoute() {
			return (
				this.$route.name === "PatientEChartImmunizationList" ||
				this.$route.name === "PatientEChartScreeningList"
			)
		},

		isFileRoute() {
			return (
				this.$route.name === "PatientEChartFilesOverview" ||
				this.$route.name === "PatientEChartFilesEdit" ||
				this.$route.name ===  "PatientEChartFilesDelete"
			)
		},
		isAllFileRoute(){
			return (
				this.$route.name === "EChartFilesOverview" ||
				this.$route.name === "EChartFilesEdit" ||
				this.$route.name === "EChartFilesDelete"
			)
		},
		isImagingRoute(){
			return this.$route.name === "patientImagingList"
		},
		islabsRoute(){
			return this.$route.name === "patientLabsList"
		},
		isTemplateRoute(){
			return this.$route.name === "patientTemplateList"
		},
		isMeasurementRoute() {
			return (
				this.$route.name === "patientMeasurementList" ||
				this.$route.name === "patientMeasurementGroupList" 
			)
		},
		isTemplateMasterRoute(){
			return (
				this.$route.name === "templateMasterList" 
			)
		},
		isTasksRoute(){
			return (
				this.$route.name === "PatientEChartActiveTaskList" ||
				this.$route.name === "PatientEChartCompleteDeleteTaskList"
			)
		},
		isAllTasksRoute(){
			return (
				this.$route.name === "AllPatientActiveTaskList" ||
				this.$route.name === "AllPatientCompleteDeleteTaskList"
			)
		},

	},
	components: { 
		
	}
};
</script>